import React, { useEffect, useState } from "react";
import styles from "../../css/style_sedes.module.css";

/**
 * Componente Mapa que muestra un mapa de Google Maps con marcadores.
 * @param {Object[]} allMarkers - Lista de todos los marcadores.
 * @param {Object[]} filteredMarkers - Lista de marcadores filtrados.
 * @param {Object} selectedCoordinates - Coordenadas seleccionadas para centrar el mapa.
 */
export default function Mapa({ allMarkers, filteredMarkers, selectedCoordinates }) {
  const [map, setMap] = useState(null);
  const [currentMarkers, setCurrentMarkers] = useState([]);

  // Inicializa el mapa y agrega los marcadores iniciales.
  useEffect(() => {
    const initMap = () => {
      const mapOptions = { center: { lat: -12.0515179, lng: -77.02190145 }, zoom: 15 };
      const newMap = new window.google.maps.Map(document.getElementById("map"), mapOptions);
      setMap(newMap);
      allMarkers.forEach(marker => {
        const newMarker = new window.google.maps.Marker({ position: marker.position, map: newMap, title: marker.title });
        setCurrentMarkers(prevMarkers => [...prevMarkers, newMarker]);
      });
    };

    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyA5Ykb2Q37RU4c7m3W1JGzuRUVJlcahQPE&libraries=places&callback=initMap`;
    script.defer = true;
    window.initMap = initMap;
    document.head.appendChild(script);
    return () => { delete window.initMap; };
  }, [allMarkers]);

  // Muestra las coordenadas seleccionadas en la consola.
  useEffect(() => { if (selectedCoordinates) console.log('Coordenadas seleccionadas:', selectedCoordinates); }, [selectedCoordinates]);

  // Actualiza los marcadores filtrados en el mapa.
  useEffect(() => {
    if (map) {
      currentMarkers.forEach(marker => marker.setMap(null));
      filteredMarkers.forEach(marker => {
        const newMarker = new window.google.maps.Marker({ position: marker.position, map, title: marker.title });
        setCurrentMarkers(prevMarkers => [...prevMarkers, newMarker]);
      });

      if (filteredMarkers.length > 0) {
        const bounds = new window.google.maps.LatLngBounds();
        filteredMarkers.forEach(marker => bounds.extend(marker.position));
        map.fitBounds(bounds);
        map.setZoom(filteredMarkers.length < 3 ? 15 : 11.9);
      }
    }
  }, [filteredMarkers, map]);

  // Mueve el mapa suavemente a las coordenadas seleccionadas.
  useEffect(() => {
    if (map && selectedCoordinates) {
      const moveMapSmoothly = (newCoordinates) => {
        const startLat = map.getCenter().lat(), startLng = map.getCenter().lng();
        const { lat: endLat, lng: endLng } = newCoordinates;
        const deltaLat = endLat - startLat, deltaLng = endLng - startLng, steps = 5, delay = 15;
        let currentStep = 0;
        const moveStep = () => {
          const progress = currentStep / steps;
          map.panTo(new window.google.maps.LatLng(startLat + deltaLat * progress, startLng + deltaLng * progress));
          if (++currentStep <= steps) setTimeout(moveStep, delay);
        };
        moveStep();
      };
      moveMapSmoothly(selectedCoordinates);
      map.setZoom(14);
    } else if (map) {
      map.setCenter({ lat: -12.0515179, lng: -77.02190145 });
      map.setZoom(11);
    }
  }, [map, selectedCoordinates]);

  return <div id="map" className={styles.ContenedorMapa} style={{ height: "703px" }} />;
}
